export const GraphChartData = [
  {
    time: "5 am",
    value: 50,
  },
  {
    time: "6 am",
    value: 100,
  },
  {
    time: "7 am",
    value: 400,
  },
  {
    time: "8 am",
    value: 500,
  },
  {
    time: "9 am",
    value: 800,
  },
  {
    time: "10 am",
    value: 320,
  },
  {
    time: "11 am",
    value: 500,
  },
  {
    time: "12 pm",
    value: 280,
  },
  {
    time: "1 pm",
    value: 300,
  },
  {
    time: "2 pm",
    value: 200,
  },
  {
    time: "3 pm",
    value: 400,
  },
  {
    time: "4 pm",
    value: 700,
  },
  {
    time: "5 pm",
    value: 300,
  },
  {
    time: "6 pm",
    value: 500,
  },
  {
    time: "7 pm",
    value: 600,
  },
  {
    time: "8 pm",
    value: 400,
  },
  {
    time: "9 pm",
    value: 400,
  },
  {
    time: "10 pm",
    value: 200,
  },
  {
    time: "11 pm",
    value: 70,
  },
];
