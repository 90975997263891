export const BarChartDATA = [
  {
    Number: "1 PM",
    Ranges: 325,
  },
  {
    Number: "2 PM",
    Ranges: 250,
  },
  {
    Number: "3 PM",
    Ranges: 380,
  },
  {
    Number: "4 PM",
    Ranges: 220,
  },
  {
    Number: "5 PM",
    Ranges: 325,
  },
  {
    Number: "6 PM",
    Ranges: 180,
  },
  {
    Number: "7 PM",
    Ranges: 220,
  },
];
